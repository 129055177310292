import { format } from "date-fns";
import { useState, useEffect } from "react";
import { Spinner } from "../../../../shared";

const TablaProcesos = ({ data, onClick, onChange, sort, setSort }) => {
  const [seleccion, setSeleccion] = useState(null);

  useEffect(() => {
    const persona_id = localStorage.getItem("reportes-atrasos-persona");
    if (persona_id) {
      setSeleccion(persona_id);
    }
  }, []);
  return (
    <table
      className="table table-hover align-middle"
      style={{
        overflowY: "auto",
      }}
    >
      <thead className="border-0 sticky">
        <tr
          className="border tr_sticky"
          style={{
            borderRadius: "20px",
            background: "rgba(217, 217, 217, 0.5)",
          }}
        >
          <th className="border-0 fs-5 p-1">
            <div
              className="border-0 p-1 text-nowrap"
              style={{ borderRadius: "10px" }}
            >
              Acciones
            </div>
          </th>
          <th className="border-0 fs-5 p-1">
            <div
              className="border-0 p-1 text-nowrap"
              style={{ borderRadius: "10px" }}
            >
              Proceso
            </div>
          </th>
          <th className="border-0 fs-5 p-1">
            <label
              className="btn fs-5 fw-bold border-0 p-1 d-flex justify-content-between text-nowrap sort"
              style={{ borderRadius: "10px" }}
            >
              Fecha
              
              <div
                className={
                  sort == "-created_at" || sort == "created_at" ? "" : "sort-arrow"
                }
              >
                {sort == "created_at" ? (
                  <i class="bi bi-arrow-down"></i>
                ) : (
                  <i class="bi bi-arrow-up"></i>
                )}
                <button
                  className="d-none"
                  name="sort"
                  onClick={(event) =>
                    onClick(event, {
                      sort: sort == "-created_at" ? "created_at" : "-created_at",
                    })
                  }
                ></button>
              </div>
            </label>
          </th>
          <th className="border-0 fs-5 p-1">
            <label
              className="btn fs-5 fw-bold border-0 p-1 d-flex justify-content-between text-nowrap sort"
              style={{ borderRadius: "10px" }}
            >
              Cliente
              <div
                className={
                  sort == "-persona.apellido" || sort == "persona.apellido" ? "" : "sort-arrow"
                }
              >
                {sort == "persona.apellido" ? (
                  <i class="bi bi-arrow-down"></i>
                ) : (
                  <i class="bi bi-arrow-up"></i>
                )}
                <button
                  className="d-none"
                  name="sort"
                  onClick={(event) =>
                    onClick(event, {
                      sort: sort == "-persona.apellido" ? "persona.apellido" : "-persona.apellido",
                    })
                  }
                ></button>
              </div>
            </label>
          </th>
          <th className="border-0 fs-5 p-1">
            <div
              className="border-0 p-1 text-nowrap"
              style={{ borderRadius: "10px" }}
            >
              Telefono
            </div>
          </th>
          <th className="border-0 fs-5 p-1">
            <div
              className="border-0 p-1 text-nowrap"
              style={{ borderRadius: "10px" }}
            >
              Al día
            </div>
          </th>
          <th className="border-0 fs-5 p-1">
            <div
              className="border-0 p-1 text-nowrap"
              style={{ borderRadius: "10px" }}
            >
              Atrasados
            </div>
          </th>
          <th className="border-0 fs-5 p-1">
            <label
              className="btn fs-5 fw-bold border-0 p-1 d-flex justify-content-between text-nowrap sort"
              style={{ borderRadius: "10px" }}
            >
              Último pago
              <div
                className={
                  sort == "-persona.ultimo_pago.created_at" || sort == "persona.ultimo_pago.created_at" ? "" : "sort-arrow"
                }
              >
                {sort == "persona.ultimo_pago.created_at" ? (
                  <i class="bi bi-arrow-down"></i>
                ) : (
                  <i class="bi bi-arrow-up"></i>
                )}
                <button
                  className="d-none"
                  name="sort"
                  onClick={(event) =>
                    onClick(event, {
                      sort: sort == "-persona.ultimo_pago.created_at" ? "persona.ultimo_pago.created_at" : "-persona.ultimo_pago.created_at",
                    })
                  }
                ></button>
              </div>
            </label>
          </th>
          <th className="border-0 fs-5 p-1">
            <label
              className="btn fs-5 fw-bold border-0 p-1 d-flex justify-content-between text-nowrap sort"
              style={{ borderRadius: "10px" }}
            >
              Ultima tarea
              <div
                className={
                  sort == "-persona.ultimaTarea.fecha_vencimiento" || sort == "persona.ultimaTarea.fecha_vencimiento" ? "" : "sort-arrow"
                }
              >
                {sort == "persona.ultimaTarea.fecha_vencimiento" ? (
                  <i class="bi bi-arrow-down"></i>
                ) : (
                  <i class="bi bi-arrow-up"></i>
                )}
                <button
                  className="d-none"
                  name="sort"
                  onClick={(event) =>
                    onClick(event, {
                      sort: sort == "-persona.ultimaTarea.fecha_vencimiento" ? "persona.ultimaTarea.fecha_vencimiento" : "-persona.ultimaTarea.fecha_vencimiento",
                    })
                  }
                ></button>
              </div>
            </label>
          </th>
          <th className="border-0 fs-5 p-1">
            <div
              className="border-0 p-1 text-nowrap"
              style={{ borderRadius: "10px" }}
            >
              Estado
            </div>
          </th>
        </tr>
      </thead>

      {!data ? (
        <td className="my-0 border-0 p-0" colSpan={10}>
          <Spinner />
        </td>
      ) : (
        <tbody className="border-0">
          {data.map((d, index) => (
            <tr
              className="border border-end-0 border-start-0"
              style={{
                background: seleccion == d.persona.id ? "#67a9ff" : "",
              }}
              onClick={() => {
                //setSeleccion(d.persona.id);
                //localStorage.setItem("reportes-atrasos-persona", d.persona.id);
              }}
            >
              <td className="border-0">
                <div className="d-flex">
                  <label
                    className="btn btn-primary fs-6 d-flex  text-nowrap me-2"
                    title="editar"
                  >
                    <i class="bi bi-pencil-square"></i>
                    <button
                      className="d-none"
                      onClick={() => {
                        window.location.href =
                          "/gestion-mora/reportes-atrasos/editar/" +
                          d.persona.id +
                          "/" +
                          d.id;
                      }}
                    ></button>
                  </label>

                  <label
                    className="btn btn-danger fs-6 d-flex text-nowrap"
                    title="eliminar"
                  >
                    <i class="bi bi-trash"></i>
                    <button
                      className="d-none"
                      name="eliminar-proceso"
                      onClick={(event) => onClick(event, d)}
                    ></button>
                  </label>
                </div>
              </td>
              <td className="border-0">{d.id}</td>
              <td className="border-0">
                {format(new Date(d.created_at), "dd/MM/yyyy").toString()}
              </td>
              <td className="border-0">
                <a
                  className="text-dark"
                  href={`/clientes/ver/${d.persona.id}`}
                  target="_blank"
                >
                  {d.persona.apellido + " " + d.persona.nombre}
                </a>
              </td>
              <td className="border-0">
                {d.persona.contactos.map((c, index) => (
                  <p className="text-nowrap">
                    {c.nro_telefono_prefix + "-" + c.nro_telefono}
                  </p>
                ))}
              </td>
              <td className="border-0">
                {d.persona.creditos_al_dia.map((cd) => (
                  <a
                    className="d-block "
                    href={`/creditos/ver/${cd.id}`}
                    target="_blank"
                  >
                    {`${cd.id}`}
                  </a>
                ))}
              </td>
              <td className="border-0">
                {d.persona.creditos_atrasados.map((cd) => (
                  <a
                    className="d-block "
                    href={`/creditos/ver/${cd.id}`}
                    target="_blank"
                  >
                    {`${cd.id}`}
                  </a>
                ))}
              </td>
              <td className="border-0">
                {d.persona.ultimo_pago.map((p) => (
                  <p className="d-block ">
                    {format(new Date(p.created_at), "dd/MM/yyyy").toString()}
                  </p>
                ))}
              </td>
              <td className="border-0">
                {d.ultima_tarea.length > 0
                  ? format(
                      new Date(d.ultima_tarea[0].fecha_vencimiento),
                      "dd/MM/yyyy"
                    ).toString()
                  : "Sin tareas"}
              </td>
              <td className="border-0">{d.estado}</td>
            </tr>
          ))}
        </tbody>
      )}
      <tfoot></tfoot>
    </table>
  );
};

export default TablaProcesos;
