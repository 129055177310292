import React, { useState, useEffect } from "react";
import ListarPreCalificaciones from "./ListarPreCalificaciones";
import { Spinner, clienteAxios } from "../shared/";

const PreCalificaciones = (props) => {
  const [propsId] = useState(props.match.params.id);
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [lista, setLista] = useState(null);
  const [mostarFormulario, setMostrarFormulario] = useState(null);
  const [consultar, setConsultar] = useState(true);
  const [Seleccionado, setSeleccionado] = useState(null);

  useEffect(() => {
    const cargar = async () => {
      if (!consultar) return;

      try {
        setLoadingLocal(true);
        const result = await clienteAxios.get(
          "/PersonaPreCalificacion/getAllUser",
          {
            params: { id: propsId },
          }
        );
        setLista(result.data.precalificaciones);
        setLoadingLocal(null);
      } catch (e) {}
      setConsultar(null);
    };

    cargar();
  }, [mostarFormulario, consultar, Seleccionado, propsId]);

  return (
    <div className="center-block">
      <br></br>
      <h2 className="text-center">
        Análisis Crediticios de {lista ? lista.personas[0].nombre + " " +lista.personas[0].apellido : null}
      </h2>
      {loadingLocal || !lista ? (
        <Spinner />
      ) : (
        <>
          <br></br> <br></br>
          <ListarPreCalificaciones
            lista={lista}
            setSeleccionado={setSeleccionado}
            setMostrarFormulario={setMostrarFormulario}
            setConsultar={setConsultar}
          />
        </>
      )}
    </div>
  );
};

export default PreCalificaciones;
