import { format } from "date-fns";
import { memo, useEffect, useState } from "react";

const autocomplete = {
  top: "60px",
  zIndex: "2",
};

const Tarea = ({
  data,
  setData,
  persona,
  usuario,
  paramsA,
  setParamsA,
  asignados,
  tipo,
  form,
  setForm,
  onClick,
  fetchAsignados,
}) => {
  const onChange = (e, value) => {
    switch (e.target.name) {
      case "asunto":
      case "descripcion":
      case "prioridad":
      case "fecha_vencimiento":
      case "hora_contacto":
      case "estado":
      case "subTipo":
      case "resultado":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "tipo":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
            tipo_id: tipo.filter((t, index) => t.name == e.target.value)[0].id,
          });
        }
        break;
      case "buscar":
        {
          setParamsA({
            ...paramsA,
            [e.target.name]: e.target.value,
            page: 1,
            paginator: 30,
          });
          let params = {
            ...paramsA,
            [e.target.name]: e.target.value,
            page: 1,
            paginator: 30,
          };
          fetchAsignados(params);
        }
        break;
    }
  };
  
  return (
    <div class="body p-4">
      {persona ? (
        <div className="row">
          <div className="col-md-6">
            <div class="mb-3 w-100">
              <label class="form-label fw-bold  fs-5">Usuario Creador</label>
              <input
                type="text"
                class="form-control fs-5 py-1"
                style={{ borderRadius: "20px" }}
                name="interes_moratorio"
                onChange={(event) => onChange(event)}
                value={usuario.nombre}
                disabled={true}
              />
            </div>
            <div class="mb-3 w-100">
              <label
                class="form-label fw-bold text-nowrap me-2 fs-5"
                style={{ width: "200px" }}
              >
                Estado
              </label>
              <select
                class="form-select fs-4 py-1"
                style={{ borderRadius: "20px" }}
                aria-label="Default select example"
                name="estado"
                onChange={(event) => onChange(event)}
              >
                {["pendiente", "seguimiento", "resuelto", "descartada"].map(
                  (est, index) => {
                    if (est == form.estado) {
                      return <option selected>{est}</option>;
                    }
                    return <option value={est}>{est}</option>;
                  }
                )}
              </select>
            </div>
            <div class="mb-3 w-100">
              <label
                class="form-label fw-bold text-nowrap me-2 fs-5"
                style={{ width: "200px" }}
              >
                Prioridad
              </label>
              <select
                class="form-select fs-4 py-1"
                style={{ borderRadius: "20px" }}
                name="prioridad"
                onChange={(event) => onChange(event)}
              >
                {["baja", "media", "normal", "alta"].map((prd, index) => {
                  if (prd == form.prioridad) {
                    return <option selected>{prd}</option>;
                  }
                  return <option value={prd}>{prd}</option>;
                })}
              </select>
            </div>
            <div class="mb-3 w-100">
              <label class="form-label fw-bold fs-5">Cliente</label>
              <input
                type="text"
                class="form-control fs-5 py-1"
                style={{ borderRadius: "20px" }}
                name="asignado"
                onChange={(event) => onChange(event)}
                value={persona.nombre + " " + persona.apellido}
                disabled={true}
              />
            </div>

            <div className="mb-3  align-items-center">
              <label
                class="form-label fw-bold text-nowrap me-2 fs-5 fw-bold border-0"
                style={{ width: "200px" }}
              >
                Asignar
              </label>
              <div class="input-group border-0">
                <input
                  type="text"
                  class="form-control fs-5 py-1"
                  style={{ borderRadius: "20px" }}
                  placeholder="Busca una persona para añadir un gasto."
                  aria-describedby="basic-addon2"
                  autoComplete="off"
                  name="buscar"
                  onChange={(event) => onChange(event)}
                  value={paramsA.buscar}
                />

                {paramsA.buscar.trim() !== "" ? (
                  <ul
                    class="list-group position-absolute w-100"
                    style={autocomplete}
                  >
                    {asignados.map((asign) => (
                      <li class="list-group-item w-100 p-0">
                        <label className="border-0  btn btn-outline-dark w-100 d-flex justify-content-start">
                          <div className="me-2">{asign.email}</div>
                          <div className="">
                            <span className="fw-bold">| </span>
                            {asign.name}
                          </div>
                          <button
                            title="Seleccionar persona"
                            className="d-none"
                            name="asignado"
                            onClick={(event) => onClick(event, asign)}
                          ></button>
                        </label>
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div class="mb-3 w-100">
              <label
                for="exampleFormControlInput1"
                class="form-label fw-bold fs-5"
              >
                Hora de contacto
              </label>
              <input
                type="time"
                class="form-control fs-5 py-1"
                style={{ borderRadius: "20px" }}
                name="hora_contacto"
                onChange={(event) => onChange(event)}
                value={form.hora_contacto}
              />
            </div>
            <div class="mb-3 w-100">
              <label class="form-label fw-bold fs-5">Vencimiento</label>
              <input
                type="date"
                class="form-control fs-5 py-1"
                style={{ borderRadius: "20px" }}
                name="fecha_vencimiento"
                onChange={(event) => onChange(event)}
                value={form.fecha_vencimiento}
              />
            </div>

            <div class="mb-3 w-100">
              <label
                class="form-label fw-bold text-nowrap me-2 fs-5"
                style={{ width: "200px" }}
              >
                Tipo | SubTipo
              </label>
              <select
                class="form-select fs-4 py-1"
                style={{ borderRadius: "20px" }}
                aria-label="Default select example"
                name="tipo"
                onChange={(event) => onChange(event)}
              >
                {!form.tipo ? <option selected></option> : ""}
                {tipo.map((tp, index) => {
                  if (tp.name == form.tipo) {
                    return <option selected>{tp.name}</option>;
                  }
                  return <option value={tp.name}>{tp.name}</option>;
                })}
              </select>
            </div>

            <div class="mb-3 w-100">
              <label
                class="form-label fw-bold text-nowrap me-2 fs-5"
                style={{ width: "200px" }}
              >
                Resultado
              </label>
              <select
                class="form-select fs-4 py-1"
                style={{ borderRadius: "20px" }}
                aria-label="Default select example"
                name="resultado"
                onChange={(event) => onChange(event)}
              >
                {form.resultado == "" || !form.resultado  ? <option selected></option> : ""}
                {["Exito", "Fracaso"].map((res, index) => {
                  if (res == form.resultado) {
                    return <option selected>{res}</option>;
                  }
                  return <option value={res}>{res}</option>;
                })}
              </select>
            </div>
          </div>

          <div className="col-md-12">
            <div class="mb-3 w-100">
              <label class="form-label fw-bold fs-5">Asunto</label>
              <input
                type="text"
                class="form-control fs-5 py-1"
                style={{ borderRadius: "20px" }}
                name="asunto"
                onChange={(event) => onChange(event)}
                value={form.asunto}
              />
            </div>
            <div class="mb-3 w-100">
              <label class="form-label fw-bold fs-5">Tarea</label>
              <textarea
                type="text"
                class="form-control fs-5 py-1"
                style={{ borderRadius: "20px" }}
                name="descripcion"
                onChange={(event) => onChange(event)}
                value={form.descripcion}
              ></textarea>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <span>Selecciona una persona para continuar</span>
        </div>
      )}
    </div>
  );
};

export default Tarea;
