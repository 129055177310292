import { format } from "date-fns";
import { memo, useEffect, useState } from "react";

const autocomplete = {
  top: "60px",
  zIndex: "2",
};

const Seguimiento = ({
  tareas,
  data,
  setData,
  form,
  setForm,
  usuario,
  onClick,
}) => {
  const onChange = (e, value) => {
    switch (e.target.name) {
      case "fecha_vencimiento":
      case "comentarios":
      case "tarea_id":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
        }
        break;
    }
  };

  return (
    <div className="body p-4">
      <div className="row">
        <div className="col-md-12">
          <div className=" border-0">
            <label
              className="form-label fw-bold text-nowrap me-2 fs-5"
              style={{ width: "200px" }}
            >
              Tarea
            </label>
            <input
              type="text"
              class="form-control fs-5 py-1"
              style={{ borderRadius: "20px" }}
              value={form.tarea_id}
              disabled={true}
            />
          </div>

          
          <div class="mb-3 w-100">
              <label class="form-label fw-bold fs-5">Vencimiento</label>
              <input
                type="date"
                class="form-control fs-5 py-1"
                style={{ borderRadius: "20px" }}
                name="fecha_vencimiento"
                onChange={(event) => onChange(event)}
                defaultValue={form.fecha_vencimiento}
              />
            </div>

          <div className="mb-3 w-100">
            <label className="form-label fw-bold fs-5">Comentario</label>
            <textarea
              type="text"
              className="form-control fs-5 py-1"
              style={{ borderRadius: "20px" }}
              name="comentarios"
              onChange={(event) => onChange(event)}
              value={form.comentarios}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Seguimiento;
