import { Calendar, momentLocalizer, Views  } from "react-big-calendar";
import moment from "moment/moment";
import 'moment/locale/es'; // Importa el locale en español
import "react-big-calendar/lib/css/react-big-calendar.css";
import { format, startOfWeek, getDay } from 'date-fns';
import { useCallback } from "react";
import { enUS } from 'date-fns/locale'; // Usa el locale adecuado para tu región
import "./ScrollStyle.css";

const localizer = momentLocalizer(moment); // or globalizeLocalizer
const views = ['month', 'week', 'day', 'agenda'];

const formats = {
  dateFormat: 'DD MMM YYYY',
  dayFormat: 'D',
  weekdayFormat: 'dddd',
  monthHeaderFormat: 'MMMM YYYY',
  agendaDateFormat: 'DD MMM YYYY',
};

const messages = {
  month: 'Mes',
  week: 'Semana',
  day: 'Día',
  previous: 'Anterior',
  next: 'Siguiente',
  today: 'Hoy',
  date: 'Fecha',
  time: 'Vencimiento',
  event: 'Tarea',
  noEventsInRange: 'No hay eventos en este rango.',
};

const priorityBg = (priority) => {
  switch (priority) {
    case 'baja':{
      return '#F3F3F3' } break;
    case 'normal':{
      return '#0569F0'} break;
    case 'media':{
      return '#FF9E1B'} break;
    case 'alta': {
      return '#FF2626'
    } break;
  }
}

const priorityColor = (priority) => {
  switch (priority) {
    case 'baja':
      return '#000000'
    case 'normal':
      return '#ffffff'
    case 'media':
      return '#ffffff'
    case 'alta':
      return '#ffffff'
      break;
  }
}

const Calendario = ({ onClick, onChange, data, navigateCalendar, viewCalendar, date }) => {

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => 
      ({
      
      ...{
        className: `p-1 overflow-auto `,
        style: { 
          minHeight: "50px",
          background: priorityBg(event.priority),
          color: priorityColor(event.priority)
        }
      },
    }),
    []
  );


  return (
    <div className="">
      <div className="row">
        <div className="col-md-12 mb-5 d-flex justify-content-start align-items-center">
          <h3 className="text-nowrap me-4 fw-bold">Calendario</h3>

          <div class="input-group">
            <button
              class="input-group-text bg-transparent border-0"
              id="basic-addon1"
            >
              <i class="bi bi-search"></i>
            </button>
            <input
              type="text"
              class="form-control"
              style={{ borderRadius: "20px", maxWidth: "400px" }}
              placeholder="Buscar por tarea"
              aria-label="Username"
              aria-describedby="basic-addon1"
              name="asunto"
              onKeyUp={(e) => {
                if (e.code === "Enter") {
                  onClick(e, null);
                }
              }}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="myCustomHeight" style={{ height: "1000px" }}>
            <Calendar
              messages={messages}
              localizer={localizer}
              eventPropGetter={eventPropGetter}
              defaultDate={date}
              events={data}
              views={views}
              defaultView={Views.AGENDA}
              showMultiDayTimes

              date={date} // Establece la fecha actual en el calendario
              onNavigate={navigateCalendar} // Maneja el cambio de fecha
              onView={viewCalendar} // Maneja el cambio de vista
              formats={formats}
              max={2}
              popup
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendario;
