import axios from 'axios';


//https://ayuu.ar/Api/public
//https://test.ayuu.ar/Api/public
//http://localhost/SISTEMA-CREDITO/backend-old/public

// Default config options
const defaultOptions = {
  baseURL: 'https://test.ayuu.ar/Api/public',
  headers: {
    'Content-Type': 'application/json',
  },
};

//Create instance
let clienteAxios = axios.create(defaultOptions);

//Set the AUTH token for any request
clienteAxios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export default clienteAxios;