import { format } from "date-fns";

const TablaTareas = ({ data, onClick, onChange }) => {
  return (
    <table
      className="table align-middle"
      style={{
        overflowY: "auto",
      }}
    >
      <thead className="border-0 sticky">
        <tr
          className="border-0 tr_sticky bg-secondary text-white"
          style={{
            borderRadius: "20px",
            background: "rgba(217, 217, 217, 1)",
          }}
        >
          <th
            className="border-0 fs-5 p-3"
            style={{ borderRadius: "50px 50px 50px 50px" }}
          >
            <div
              className="border-0 p-1 text-nowrap"
              style={{ borderRadius: "10px", fontSize: "20px" }}
            >
              Lista de tareas
              <span
                className="fs-6 bg-primary p-2 ms-2"
                style={{ borderRadius: "20px" }}
              >
                {data.length} tareas
              </span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody className="border-0">
        {data.map((d, index) => (
          <tr className="border-0">
            <td className="border-0">
              <div
                className="row border py-3 card-task"
                style={{ borderRadius: "20px" }}
              >
                <div className="col-md-8">
                  <div className="row">
                    <div className="mb-3 fw-bold fs-5 col-md-3">
                      Vencimiento:
                      <span className="fw-normal ms-1 d-block">
                        {format(
                          new Date(
                            d.tarea_fecha_vencimiento + "T" + d.hora_contacto
                          ),
                          "dd/MM/yyyy"
                        ).toString()}
                      </span>
                    </div>
                    <div className="mb-3 fw-bold fs-5 col-md-3">
                      Asignado:
                      <span className="fw-normal ms-1 d-block">
                        {d.usuario_asignado.name +
                          " " +
                          d.usuario_asignado.lastname}
                      </span>
                    </div>
                    <div className="mb-3 fw-bold fs-5 col-md-3">
                      Estado:
                      <span className="fw-normal ms-1 d-block">{d.estado}</span>
                    </div>
                    <div className="mb-3 fw-bold fs-5 col-md-3">
                      {d.estado == "resuelto" ? (
                        <i class="ms-2 bi bi-check-square-fill text-success fs-1"></i>
                      ) : d.estado == "descartada" ? (
                        <i class="ms-2 bi bi-x-square-fill text-danger fs-1"></i>
                      ) : d.estado == "pendiente" ? (
                        <i class="ms-2 bi bi-slash-circle-fill text-secondary fs-1"></i>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div class="mb-3  col-md-3 fw-bold fs-5 d-block">
                      Asunto
                      <span className="fw-normal ms-1 d-block">{d.asunto}</span>
                    </div>
                    <div class="mb-3  col-md-3 fw-bold fs-5">
                      Tipo
                      <span className="fw-normal ms-1 d-block">
                        {d.tipo.tipo}
                      </span>
                    </div>
                    <div class="mb-3  col-md-3 fw-bold fs-5">
                      SubTipo
                      <span className="fw-normal ms-1 d-block">
                        {d.tipo.subtipo}
                      </span>
                    </div>
                  </div>

                  <div className="mb-3 col-md-12">
                    <textarea
                      type="text"
                      class="form-control fs-5 py-1 bg-white"
                      style={{ borderRadius: "20px" }}
                      name="descripcion"
                      onChange={(event) => onChange(event)}
                      value={d.descripcion}
                      disabled
                    ></textarea>
                  </div>

                  <div className="mb-3 d-flex">
                    <span className="text-black fw-bold me-2">
                      Ultimo seguimiento{" "}
                    </span>
                    <button
                      className=" btn text-primary p-0 m-0 border border-primary d-flex justify-content-center align-items-center me-2"
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#agregar-seguimiento"
                      id="btn-agregar-seguimiento"
                      name="seguimiento"
                      title="Crear nuevo seguimiento"
                      onClick={(e) =>
                        onClick({ target: { name: "seguimiento" } }, d)
                      }
                    >
                      <i class="bi bi-plus-lg"></i>
                    </button>
                    <button
                      className="btn text-danger p-0 m-0 border border-danger d-flex justify-content-center align-items-center"
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                      }}
                      title="Eliminar ultimo seguimiento"
                      name="eliminar-seguimiento"
                      onClick={(e) => onClick(e, d.ultimo_seguimiento[0].id)}
                    >
                      <i class="bi bi-trash" style={{ fontSize: "10px" }}></i>
                    </button>
                  </div>

                  <div className="mb-3">
                    {d.ultimo_seguimiento.length > 0 ? (
                      <div>
                        <p>{d.ultimo_seguimiento[0].comentarios}</p>
                        <p>
                          <strong>Vencimiento</strong>{" "}
                          {format(
                            new Date(
                              d.ultimo_seguimiento[0].fecha_vencimiento +
                                " 00:00:00"
                            ),
                            "dd/MM/yyyy"
                          ).toString()}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-2">
                  <ul class="list-group">
                    <li class="bg-transparent list-group-item text-nowrap border-0">
                      {d.prioridad == "baja" ? (
                        <div
                          className="mx-auto d-flex justify-content-center  align-items-center"
                          style={{
                            padding: "10px",
                            borderRadius: "20px",
                            background: "#F3F3F3",
                          }}
                        >
                          <span className="text-black d-flex">
                            <i class="bi bi-circle-fill me-2"></i>
                            Baja
                          </span>
                        </div>
                      ) : null}
                      {d.prioridad == "normal" ? (
                        <div
                          className="mx-auto d-flex justify-content-center  align-items-center"
                          style={{
                            padding: "10px",
                            borderRadius: "20px",
                            background: "#0569F0",
                          }}
                        >
                          <span className="text-white d-flex">
                            <i class="bi bi-circle-fill me-2"></i>Normal
                          </span>
                        </div>
                      ) : null}
                      {d.prioridad == "media" ? (
                        <div
                          className="mx-auto d-flex justify-content-center  align-items-center"
                          style={{
                            padding: "10px",
                            borderRadius: "20px",
                            background: "#FF9E1B",
                          }}
                        >
                          <span className="text-white d-block">
                            <i class="bi bi-circle-fill me-2"></i>Media
                          </span>
                        </div>
                      ) : null}
                      {d.prioridad == "alta" ? (
                        <div
                          className="mx-auto d-flex justify-content-center  align-items-center"
                          style={{
                            padding: "10px",
                            borderRadius: "20px",
                            background: "#FF2626",
                          }}
                        >
                          <span className="text-white d-flex">
                            <i class="bi bi-circle-fill me-2"></i>Alta
                          </span>
                        </div>
                      ) : null}
                    </li>
                    <li class="bg-transparent list-group-item text-nowrap border-0">
                      <label
                        className="btn btn-primary text-white me-2 mb-2 "
                        style={{
                          borderRadius: "20px",
                        }}
                      >
                        <i class="bi bi-pencil"></i>
                        <button
                          className="d-none"
                          data-bs-toggle="modal"
                          data-bs-target="#actualizar-tarea"
                          name="modificar-tarea"
                          onClick={(event) =>
                            onClick(event, { ...d, index: index })
                          }
                        ></button>
                      </label>
                      <label
                        className="btn btn-danger text-white me-2 mb-2 "
                        style={{
                          borderRadius: "20px",
                        }}
                      >
                        <i class="bi bi-trash"></i>
                        <button
                          className="d-none"
                          name="eliminar-tarea"
                          onClick={(event) =>
                            onClick(event, { ...d, index: index })
                          }
                        ></button>
                      </label>
                      <label
                        className="btn btn-success text-white me-2 mb-2 "
                        style={{
                          borderRadius: "20px",
                        }}
                      >
                        <i class="bi bi-eye"></i>
                        <button
                          className="d-none"
                          onClick={(e) => {
                            let seg = document.getElementById(
                              "seguimientos-tarea-" + d.id
                            );
                            if (seg.classList.contains("d-none")) {
                              seg.className = "col-md-12 border py-2";
                            } else {
                              seg.className = "col-md-12 border py-2 d-none";
                            }
                          }}
                        ></button>
                      </label>
                    </li>
                    <li class="bg-transparent list-group-item text-nowrap border-0">
                      <div>Tarea Nº: {d.id}</div>
                      <div>
                        Fecha de creacion:{" "}
                        {format(
                          new Date(d.created_at),
                          "dd/MM/yyyy"
                        ).toString()}
                      </div>
                    </li>
                  </ul>
                </div>
                <div
                  className="col-md-12 border py-2 d-none"
                  id={"seguimientos-tarea-" + d.id}
                  style={{ borderRadius: "20px" }}
                >
                  <span className="px-3 mb-3">Historial</span>
                  {d.seguimientos.map((s, indexS) => (
                    <ul class="list-group my-2">
                      <li
                        class="bg-transparent list-group-item text-nowrap border-0 py-0 mb-1"
                        style={{ fontSize: "12px" }}
                      >
                        <span className="text-black fw-bold me-2">Fecha de creación</span>
                        {format(
                          new Date(s.created_at ),
                          "dd/MM/yyyy"
                        ).toString()}
                      </li>
                      <li
                        class="bg-transparent list-group-item text-nowrap border-0 py-0 mb-1"
                        style={{ fontSize: "12px" }}
                      >
                        <span className="text-black fw-bold me-2">
                          Vencimiento
                        </span>
                        {format(
                          new Date(s.fecha_vencimiento + " 00:00:00"),
                          "dd/MM/yyyy"
                        ).toString()}
                      </li>
                      <li class="bg-transparent list-group-item text-nowrap border-0">
                        {s.comentarios}
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot></tfoot>
    </table>
  );
};

export default TablaTareas;
