import apiAxios from "../config/ApiAxios";

export const ApiRestPost = async ({ setLoading, form, url }) => {
  setLoading(true);

  const response = await apiAxios({
    method: "post",
    url: url,
    data: form,
  })
    .then((result) => {
      setLoading(false);
      return result;
    })
    .catch((result) => {
      return console.log(result.response.data.msg);
    });

  return response;
};
