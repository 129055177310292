import { format } from "date-fns";

export const FormatterNumber = (number) => {
  return "$" + new Intl.NumberFormat("de-DE").format(number);
};

export const getFirstDayOfMonth = (e) => {
  const date = e ?? new Date();
  return format(
    new Date(date.getFullYear(), date.getMonth(), 1),
    "yyyy-MM-dd"
  ).toString();
};

export const getLastDayOfMonth = (e) => {
  // El primer día del siguiente mes
  const date = e ?? new Date();
  const nextMonth = date.getMonth() + 1;
  const year = date.getFullYear();
  return format(new Date(year, nextMonth, 0), "yyyy-MM-dd").toString();
};

export const getFirstDayOfWeek = (e) => {
  const date = e ?? new Date();
  const day = date.getDay();
  const diff = (day < 1 ? -6 : 1) - day; // Calcula la diferencia desde el lunes
  return format(
    new Date(date.setDate(date.getDate() + diff)),
    "yyyy-MM-dd"
  ).toString();
};

export const getLastDayOfWeek = (e) => {
  const date = e ?? new Date();
  const day = date.getDay();
  const diff = -day; // Calcula la diferencia desde el domingo
  const firstDay = new Date(date.setDate(date.getDate() + diff)); // Crear una nueva fecha para evitar modificar la original
  return format(
    new Date(firstDay.setDate(firstDay.getDate() + 6)),
    "yyyy-MM-dd"
  ).toString();
};

export const generateDateRange = (startDate, dayOfMonth) => {
  // Crear una fecha a partir del startDate y el día específico del mes
  const start = new Date(startDate.getFullYear(), startDate.getMonth(), dayOfMonth);

  // Calcular el siguiente mes
  const nextMonth = start.getMonth() + 1;
  const nextYear = start.getFullYear();

  // Si el mes es diciembre, ajustar el año
  const endMonth = nextMonth > 11 ? 0 : nextMonth;
  const endYear = nextMonth > 11 ? nextYear + 1 : nextYear;

  // Crear una fecha para el mismo día del siguiente mes
  const end = new Date(endYear, endMonth, dayOfMonth);

  // Retornar el rango de fechas
  return { start, end };
}
